<div class="attachment" [class.smallest]="fileWidth == MIN_FILE_WIDTH">
  <div class="dropArea" *ngIf="files.length == 0">
    <label class="input-label empty" [ngStyle]="{width: fileWidth + 'px'}">
      <div class="add-file">
        <p class="add">Přetáhněte sem soubory nebo<br> <span>vyberte soubor</span></p>
        <p class="plus">+</p>
      </div>
      <input multiple class="inputfile" type="file" (change)="uploadFile($event)">
    </label>
  </div>
  <context-menu (open)="onContextMenuOpen($event.item.id)">
    <ng-container *ngIf="isAllowedSetDefault">
      <ng-template contextMenuItem let-item (execute)="setDefaultFile($event.item.id)">
        Zvolit jako výchozí
      </ng-template>
    </ng-container>
    <ng-template contextMenuItem let-item
                 (execute)="applyUrl($event.item.src, false, $event?.item?.enablePreview)">
      Náhled souboru
    </ng-template>
    <ng-template contextMenuItem let-item (execute)="applyUrl($event.item.src, true)">
      Stáhnout soubor
    </ng-template>
    <ng-template contextMenuItem [divider]="true"></ng-template>
    <ng-template contextMenuItem let-item (execute)="deleteAllSelectedFiles()"
                 [enabled]="isAllowedRemoving && !isDisabled">
      <div>Smazat</div>
    </ng-template>
  </context-menu>
  <div class="files">
    <label for="{{id}}" class="input-label" *ngIf="files.length > 0" [ngStyle]="{width: fileWidth + 'px'}">
      <div class="add-file">
        <p class="add">Přetáhněte sem soubory nebo<br> <span>vyberte soubor</span></p>
        <p class="plus">+</p>
      </div>
      <input multiple type="file" class="file-input" id="{{id}}" (change)="uploadFile($event)">
    </label>
    <app-file *ngFor="let f of filesQueried"
              [serverFileData]="f"
              [uploadedPercent]="getUploadedForFile(f.id)"
              [isDragDisabled]="isDisabled || !isAllowedRemoving"
              [contextMenu]="basicMenu"
              [contextMenuSubject]="f"
              (onDelete)="deleteFile(f.id)"
              (onDragStart)="dragStart(f.id, $event)"
              (onDragEnd)="onDragEnd.emit({})"
              (click)="selectFile(f.id, $event)"
              (dblclick)="applyUrl(f.src, false, f?.enablePreview)"
              [selected]="isSelected(f.id)"
              [width]="fileWidth"
    ></app-file>
  </div>
  <div class="range">
    <app-slider [min]="MIN_FILE_WIDTH" [max]="MAX_FILE_WIDTH" [step]="10" [(value)]="fileWidth">
    </app-slider>
  </div>
</div>
